import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/performance';
import 'firebase/analytics';

import React from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import { version } from '../../package.json';

import DefaultAuthenticationFallback from '../pages/LoginPage';
import FirestoreCollectionService from '../services/Service';

//register UIkit Icons globally so they can be used throughout the site
UIkit.use(Icons);

//setup firebase
const firebaseConfig = {
  targetHostingSite: "https://crud.demo.hmgrwn.app",
  apiKey: "AIzaSyC8bEx6tRrVi-Umi_zFMQSqcII6yxatYWc",
  authDomain: "hgs-demo-crud-app.firebaseapp.com",
  databaseURL: "https://hgs-demo-crud-app.firebaseio.com",
  projectId: "hgs-demo-crud-app",
  storageBucket: "hgs-demo-crud-app.appspot.com",
  messagingSenderId: "276492327885",
  appId: "1:276492327885:web:feb99eeddca2a90c930284",
  measurementId: "G-QXL2QCLHP9"
};
export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const firestoreDb = firebaseApp.firestore();
export const firebasePerformance = firebaseApp.performance();
export const firebaseAnalytics = firebaseApp.analytics();

export const dynamicCacheService = new FirestoreCollectionService({
  collectionName: '_siteConfiguration_dbCache',
  objectSortColumn: 'ts',
  objectSortDirection: 'desc',
  db: firestoreDb
});

//use App as the root for global state
export const ApplicationContext = React.createContext(null);
//HOC to make consuming the global state easier
export const withContext = (Component) => (props) => (
  <ApplicationContext.Consumer>
    {context => <Component context={context} {...props}/>}
  </ApplicationContext.Consumer>
);
//HOC to switch between two components based on authentication state, as well as pass context
export const withSecureContext =
  (Component, Fallback=DefaultAuthenticationFallback) => withContext((props) => (
    props.context.isAuthenticated ? <Component {...props}/> : <Fallback {...props}/>
  ));

//default firebase context, generally suitable for all applications
const defaultFirebaseAppContext = ({ history }) => ({
  //firebase application
  firebase: firebaseApp,
  site: firebaseConfig.targetHostingSite,
  version: version,
  env: 'DEV',
  //firebase auth
  isAuthenticated: false,
  authUser: null,
  signOut: ()=>firebase.auth().signOut(),
  //firestore db
  db: firestoreDb,
  timestamp: firebase.firestore.FieldValue.serverTimestamp(),
  services: {},
  //route manipulation
  changeRoute: (newRoute)=>{history.push(newRoute);},
  goBack: ()=>{history.goBack();},
  //ui manipulation
  modal: UIkit.modal,
  notification: UIkit.notification,
  offcanvas: UIkit.offcanvas,
  util: UIkit.util,
  //error handling stub
  error: console.error
});

//application specific contexts
export const initializeContext = ({ history }) => {
  const context = defaultFirebaseAppContext({ history });
  const services = { dynamicCacheService };
  //install services
  context.services = services;
  //personalization
  context.darkTheme = true;
  context.pageList = [];
  context.dynamicRoutes = [];
  return context;
};
