import React, { PureComponent } from 'react';

import { QuillField } from '../../components/Util';

class QuillPageView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            content: props.page.content
        };
    }
    async componentDidMount() {
        const propsPage = this.props.page;
        const { dynamicPageService } = this.props.context.services;
        if (propsPage.dehydrated) {
            const page = await dynamicPageService.getObject(propsPage.id);
            this.setState(() => ({ content: JSON.parse(page.content) }));
        }
    }
    render() {
        const code = this.props.page.code;
        const content = this.state.content;
        if (content) {
            return (
                <div className="uk-container uk-container-small uk-animation-slide-top-small">
                    <QuillField id={`page_content_${code}`} readOnly={true} content={content} />
                </div>
            );
        } else {
            return (
                <div className="uk-container uk-container-small uk-animation-slide-top-small">
                    <h2>Loading...</h2>
                    <p>The requested page is being loaded.</p>
                </div>
            );
        }
    }
}

export default QuillPageView;