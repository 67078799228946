import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { withContext } from '../state/AppState';

const navLinks = [
  { path: '/', label:'Home', isAuth: false },
  { path: '/admin', label:'Admin', isAuth: true }
];
const navLinks_userStatus = [
  { path: '/login', label:'Login', isAuth: false, hideWhenAuth: true },
  { path: '/logout', label:'Logout', isAuth: true }
];

class OffCanvas extends PureComponent {

  componentDidUpdate(prevProps) {
    //hide
    if(prevProps.pathname!==this.props.pathname) {
      this.props.context.offcanvas(`#${this.props.id}`).hide();
    }
  }
  render() {
    const { id, context, pathname } = this.props;
    const { isAuthenticated, authUser, pageList } = context;
    const fullNavlinks = navLinks.concat(pageList).concat(navLinks_userStatus);
    return (
      <div id={id} uk-offcanvas='overlay: true'>
        <div className='uk-offcanvas-bar'>
          <button className='uk-offcanvas-close' type='button' uk-close='true'/>
          <ul className='uk-nav uk-nav-primary'>
            {fullNavlinks
              .filter(({ linkLocation })=>linkLocation==='navbar'||linkLocation===undefined)
              .filter(({ isAuth })=>isAuth===isAuthenticated||isAuth===false)
              .filter(({ hideWhenAuth })=>!isAuthenticated||(isAuthenticated&&!hideWhenAuth))
              .map(({path, label},i)=>(
                <li key={`navlink_${path}`} className={pathname===path?'uk-active':''}>
                  <Link to={path}>{label}</Link>
                </li>
            ))}
          </ul>
          {isAuthenticated && <>
            <hr/>
            <div className="uk-flex uk-flex-column uk-flex-middle">
              <span>Logged in as {authUser.displayName}</span>
              <span>
                <Link to="/userProfile" className="uk-margin-small-right">Edit Profile</Link>
              </span>
            </div>
          </>}
        </div>
      </div>
    );
  }
}
OffCanvas = withContext(OffCanvas);

const Navbar = withContext(({ context, location }) => {
  return <>
    <nav className='uk-navbar-container uk-navbar-transparent' uk-navbar='true'>
      <div className='uk-navbar-left'>
        <button className='uk-navbar-toggle' uk-navbar-toggle-icon='true'
          title='Show Menu'
          uk-toggle='target: #offCanvasArea' type='button'/>
      </div>
      <div className='uk-navbar-center'>
        <span className='uk-logo uk-navbar-item'>Demo CRUD App</span>
      </div>
    </nav>
    <OffCanvas id='offCanvasArea' pathname={location.pathname}/>
  </>;
});

export default Navbar;
