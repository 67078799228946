import React, { PureComponent, Suspense, lazy } from 'react';
import { Route, Switch, BrowserRouter, withRouter, Redirect } from 'react-router-dom';

import { initializeContext, withContext, ApplicationContext, firebaseApp, firestoreDb } from './state/AppState';

import Navbar from './components/Navbar';
import LoginPage from './pages/LoginPage';
import AdminPage from './pages/AdminPage';
import { dynamicPageRoute } from './resources/objectConfigurations';
import FirestoreCollectionService from './services/Service';

import './App.scss';

const UserProfile = lazy(() => import('./pages/UserProfile'));

const Home = withContext(({ context }) => {
  return (
    <div className='uk-container uk-container-small uk-animation-slide-top-small'>
      <h2>Welcome</h2>
      <p>This is a demonstration web application by HomeGrown Solutions.</p>
      <p>You can login and create new pages/object types. Feel free to
      explore the different features offered by an advanced HGS web app.
      Because this information may be accessible to the general public, please
      refrain from inputing any sensitive information into this demo app.</p>
    </div>
  );
});

const Error404 = withContext(({ context }) => {
  return (
    <div className='uk-container uk-container-small uk-animation-slide-top-small'>
      <h2>Page Not Found</h2>
      <p>The requested page could not be found.</p>
    </div>
  );
});

const Loading = withContext(({ context }) => {
  return (
    <div className='uk-container uk-container-small uk-animation-slide-top-small'>
      <h2>Loading...</h2>
      <p>The requested page is being loaded.</p>
    </div>
  );
});

//login page needs to be wrapped with withContext
//this is because withSecureContext depends on the LoginPage, so it can't wrap itself
const Login = withContext(LoginPage);
const Logout = withContext(({ context }) => {
  context.signOut();
  return <Redirect to='/' />;
});

class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = initializeContext({ history: props.history });
    this.state.updateContext = this.setState;
    const sessionUser = sessionStorage.getItem('authUser');
    if (sessionUser) {
      this.state.authUser = sessionUser;
      this.state.isAuthenticated = true;
      this.updateServicesAuthStates(true);
    }
  }
  updateServicesAuthStates = (authState) => {
    for (const service of Object.values(this.state.services)) {
      service.changeAuthState(authState);
    }
  };
  updateDynamicResources = async () => {
    this.setState(() => ({ loadingApp: true }));
    try {
      const { dynamicCacheService } = this.state.services;
      const preloadKey = this.state.isAuthenticated ? '_preload_auth' : '_preload_public';
      const appCache = await dynamicCacheService.getObject(preloadKey, false);
      //make services
      const dynamicServices = appCache.objects
        .map((obj) => ({
          db: firestoreDb,
          serviceName: obj.serviceName,
          collectionName: obj.code,
          objectSortColumn: obj.sortColumn,
          objectSortDirection: obj.sortDirection
        }))
        .reduce((acc, oConfig) => {
          return Object.assign({ [oConfig.serviceName]: new FirestoreCollectionService(oConfig) }, acc);
        }, {});
      //auth services
      Object.values(dynamicServices).forEach(s => s.changeAuthState(this.state.isAuthenticated));
      const pageList = appCache.pages.sort((a, b) => a.code.localeCompare(b.code));
      const objectsList = appCache.objects;
      const pageViewList = appCache.pageViews;
      await Promise.all(objectsList.map(dynamicServices.dynamicObjectService.processAndCacheObject));
      await Promise.all(pageViewList.map(dynamicServices.dynamicPageViewService.processAndCacheObject));
      this.setState((oldState) => ({
        pageList, objectsList, pageViewList,
        services: Object.assign({ ...dynamicServices }, oldState.services),
        loadingApp: false
      }));
    } catch (err) {
      console.error(err);
      this.setState(() => ({ loadingApp: false }));
    }
  };
  async componentDidMount() {
    firebaseApp.auth().onAuthStateChanged(async (user) => {
      if (user) {
        this.setState(() => ({ isAuthenticated: true, authUser: user }));
        this.updateServicesAuthStates(true);
        if (!user.displayName) {
          const name = window.prompt('What is your display name?');
          if (name) {
            await user.updateProfile({ displayName: name });
          }
        }
        sessionStorage.setItem('authUser', user);
      } else if (this.state.isAuthenticated) {
        this.setState(() => ({ isAuthenticated: false, authUser: null }));
        this.updateServicesAuthStates(false);
        sessionStorage.removeItem('authUser');
      }
      if (!this.state.loadingApp) await this.updateDynamicResources();
    });
    if (!this.state.loadingApp) await this.updateDynamicResources();
  }
  render() {
    const ukBackground = this.state.darkTheme ? "uk-background-secondary" : "uk-background-muted";
    const ukTheme = this.state.darkTheme ? "uk-light" : "uk-dark";
    const CatchAllComponent = this.state.loadingApp ? Loading : Error404;
    const loggedIn = this.state.isAuthenticated;
    return (
      <div className={`AppContainer ${ukTheme} ${ukBackground}`}>
        <ApplicationContext.Provider value={this.state}>
          <Suspense fallback={<Loading/>}>
            <Route path='/**' component={Navbar} />
            <main className='App'>
              <Switch location={this.props.location}>
                <Route exact path='/' component={Home} />
                <Route path='/userProfile' component={UserProfile} />
                <Route path='/login' component={Login} />
                <Route path='/logout' component={Logout} />
                {loggedIn && <Route exact path='/admin' component={AdminPage} />}
                {this.state.pageList.map((page) => {
                  const PageComponent = dynamicPageRoute(page);
                  return <Route path={page.path} component={PageComponent} key={`dyn_route_${page.code}`} />;
                })}
                <Route path='/**' component={CatchAllComponent} />
              </Switch>
            </main>
            <div className="AppFooter">
              <p>{this.state.env.toUpperCase()} - {this.state.version}</p>
            </div>
          </Suspense>
        </ApplicationContext.Provider>
      </div>
    )
  }
}

const SPAWithRouter = withRouter(App);

const RoutedApp = () => (
  <BrowserRouter>
    <SPAWithRouter />
  </BrowserRouter>
);

export default RoutedApp;
