import React, { lazy } from 'react';
import { camelCase } from 'lodash/string';

import QuillPageView from '../components/core/QuillPageView';
import { dateFromTimestamp } from '../components/core/object/ObjectDataMap';
import { QuillField } from '../components/Util';
import { withContext, withSecureContext } from '../state/AppState';
import Eta from '../components/core/template/EtaTemplateEngine';

const StackedPageView = lazy(() => import('../components/core/StackedPageView'));

const ErrorPane = ({ type, code }) => {
  console.error(`Page is targetting ${code} but that view cannot be found`);
  return (
    <div className="uk-container uk-container-xsmall">
      <h3>Configuration Error</h3>
      <p>Please contact an administrator to resolve this error.</p>
      <p>This page is targetting a view that has not been setup.</p>
    </div>
  );
};

export const dynamicPageRoute = (page) => {
  const { code, isAuth, rootObject, pageView } = page;
  const { objectName, serviceName, objectFields, tableDataMapping, editDataMapping, createDataMapping } = rootObject || {};
  const pv = pageView || {};
  const pvCode = pv.code;
  let pageComponent = withContext((props) => <ErrorPane type='invalid_page_view' code={pvCode} {...props} />);
  if (pvCode === 'pageView.object.stacked') {
    const wrapper = isAuth ? withSecureContext : withContext;
    pageComponent = wrapper((props) => {
      let pageConfig = defaultConfig_adminObject;
      if (code !== 'admin_object' && objectName && objectFields && tableDataMapping && editDataMapping && createDataMapping) {
        pageConfig = {
          ...rootObject, viewDataTransform: defaultConfig_adminPage.viewDataTransform
        };
      }
      const firestoreCollectionService = props.context.services[serviceName];
      const customConfig = Object.assign({ firestoreCollectionService }, pageConfig);
      return <StackedPageView configuration={customConfig} {...props} />;
    });
  } else if (pvCode === 'pageView.static.quill') {
    pageComponent = withContext((props) => (
      <QuillPageView page={page} {...props} />
    ));
  }
  return pageComponent;
};

export function numberToMoney(num) {
  return Number((num).toFixed(2));
}

export function renderTimestamp(ts) {
  if (!ts) return ts;
  return dateFromTimestamp(ts).toLocaleString();
}

export function safeCamelCase(str) {
  return (str && camelCase(str)) || '';
}

const fieldTypeMapping = {
  text: 'Text',
  quill: 'Quill',
  code: 'Code',
  array: 'Array',
  map: 'Map',
  number: 'Number',
  money: 'Money',
  boolean: 'Checkbox',
  dropdown: 'Dropdown',
  reference: 'Reference',
  config: 'Config',
  timestamp: 'Timestamp',
  delete: 'Delete'
};
const fieldCodeTypeMapping = {
  javascript: 'Javascript',
  html: 'HTML'
};
const fieldTypeListEntries = Object.entries(fieldTypeMapping).map(([k, v]) => ({ label: v, value: k }));
const fieldCodeTypeListEntries = Object.entries(fieldCodeTypeMapping).map(([k, v]) => ({ label: v, value: k }));
export const defaultConfig_adminObject = {
  objectName: 'Object',
  twoColumns: true,
  objectFields: {
    id: { label: 'Object Id', type: 'text' },
    name: { label: 'Name', type: 'text' },
    code: { label: 'Code', type: 'text' },
    objectFields: {
      label: 'Object Fields', type: 'map', configurable: true, forceLargeModal: true, fields: [
        { key: 'key', label: 'Key', type: 'text', required: true, readOnly: true },
        { key: 'label', label: 'Label', type: 'text', required: true },
        {
          key: 'type', label: 'Type', type: 'dropdown', listEntries: fieldTypeListEntries, required: true,
          rowCalculationFn: ({ type }) => type || 'text'
        },
        {
          key: 'codeType', label: 'Code Type', type: 'dropdown', listEntries: fieldCodeTypeListEntries,
          showFieldFn: ({ type }) => type === 'code', configOnly: true
        },
        { key: 'references', label: 'References', type: 'text', configOnly: true, showFieldFn: ({ type }) => type === 'reference' },
        {
          key: 'fields', label: 'Fields', type: 'array', configurable: true, showFieldFn: ({ type }) => (type === 'array' || type === 'map'),
          configOnly: true, fields: [
            { key: 'key', label: 'Key', type: 'text' },
            { key: 'label', label: 'Label', type: 'text', required: true },
            {
              key: 'type', label: 'Type', type: 'dropdown', listEntries: fieldTypeListEntries, required: true,
              rowCalculationFn: ({ type }) => type || 'text'
            },
            { key: 'references', label: 'References', type: 'text', configOnly: true, showFieldFn: ({ type }) => type === 'reference' },
            { key: 'min', label: 'Minimum', type: 'number', configOnly: true, showFieldFn: ({ type }) => (type === 'number' || type === 'money') },
            { key: 'max', label: 'Maximum', type: 'number', configOnly: true, showFieldFn: ({ type }) => (type === 'number' || type === 'money') },
            { key: 'step', label: 'Step', type: 'number', configOnly: true, showFieldFn: ({ type }) => (type === 'number' || type === 'money') },
            { key: 'showFieldFn', label: 'Show Condition', type: 'code', codeType: 'javascript', compactEditor: true, configOnly: true },
            { key: 'rowCalculationFn', label: 'Row Calculation', type: 'code', codeType: 'javascript', compactEditor: true, configOnly: true },
            { key: 'expandByDefault', label: 'Expand By Default', type: 'boolean', configOnly: true, showFieldFn: ({ type }) => (type === 'array' || type === 'map') },
            { key: 'required', label: 'Required', type: 'boolean', configOnly: true },
            { key: 'readOnly', label: 'Read Only', type: 'boolean', configOnly: true },
            { key: 'configOnly', label: 'Hide in Menu', type: 'boolean', configOnly: true },
            { label: 'Config', type: 'config' },
            { label: 'Delete', type: 'delete' }
          ]
        },
        {
          key: 'listEntries', label: 'List Entries', type: 'array', showFieldFn: ({ type }) => type === 'dropdown',
          configOnly: true, fields: [
            { key: 'value', label: 'Code', type: 'text', required: true },
            { key: 'label', label: 'Label', type: 'text', required: true },
          ]
        },
        { key: 'min', label: 'Minimum', type: 'number', configOnly: true, showFieldFn: ({ type }) => (type === 'number' || type === 'money') },
        { key: 'max', label: 'Maximum', type: 'number', configOnly: true, showFieldFn: ({ type }) => (type === 'number' || type === 'money') },
        { key: 'step', label: 'Step', type: 'number', configOnly: true, showFieldFn: ({ type }) => (type === 'number' || type === 'money') },
        // { key: 'showFieldFn', label: 'Show Condition', type: 'code', codeType: 'javascript', compactEditor: true, configOnly: true },
        { key: 'rowCalculationFn', label: 'Row Calculation', type: 'code', codeType: 'javascript', compactEditor: true, configOnly: true },
        { key: 'forceLargeModal', label: 'Use Large Modal', type: 'boolean', configOnly: true, showFieldFn: ({ type }) => (type === 'array' || type === 'map') },
        { key: 'expandByDefault', label: 'Expand By Default', type: 'boolean', configOnly: true, showFieldFn: ({ type }) => (type === 'array' || type === 'map') },
        { key: 'required', label: 'Required', type: 'boolean', configOnly: true },
        { key: 'readOnly', label: 'Read Only', type: 'boolean', configOnly: true },
        { label: 'Config', type: 'config' },
        { label: 'Delete', type: 'delete' }
      ]
    },
    tableDataMapping: {
      label: 'Table Data Mapping', type: 'array', configurable: true, fields: [
        { key: 'key', label: 'Key', type: 'text', required: true },
        { key: 'viewLink', label: 'View Link', type: 'boolean' },
        { key: 'truncate', label: 'Truncate Length', type: 'number', min: 0, step: 1, configOnly: true },
        { label: 'Config', type: 'config' },
        { label: 'Delete', type: 'delete' }
      ]
    },
    editDataMapping: {
      label: 'Edit Data Mapping', type: 'array', configurable: true, fields: [
        { key: 'key', label: 'Key', type: 'text', required: true },
        { key: 'required', label: 'Required', type: 'boolean' },
        {
          key: 'showFieldFn', label: 'Show Condition', type: 'code', codeType: 'javascript',
          compactEditor: true, configOnly: true, expandByDefault: true
        },
        { label: 'Config', type: 'config' },
        { label: 'Delete', type: 'delete' }
      ]
    },
    createDataMapping: {
      label: 'Create Data Mapping', type: 'array', configurable: true, fields: [
        { key: 'key', label: 'Key', type: 'text', required: true },
        { key: 'required', label: 'Required', type: 'boolean' },
        {
          key: 'showFieldFn', label: 'Show Condition', type: 'code', codeType: 'javascript',
          compactEditor: true, configOnly: true, expandByDefault: true
        },
        { label: 'Config', type: 'config' },
        { label: 'Delete', type: 'delete' }
      ]
    },
    sortColumn: { label: 'Sort Column', type: 'text' },
    sortDirection: { label: 'Sort Direction', type: 'text' },
    objectName: { label: 'Object Name', type: 'text' },
    serviceName: { label: 'Service Name', type: 'text' },
    twoColumns: { label: 'Enable Two Columns', type: 'boolean' },
    isAuth: { label: 'Secure Object', type: 'boolean' },
    updatedOn: { label: 'Last Updated On', type: 'timestamp', readOnly: true },
    updatedBy: { label: 'Last Updated By', type: 'text', readOnly: true },
  },
  tableDataMapping: [
    { key: 'id', viewLink: true },
    { key: 'name' },
    { key: 'code' }
  ],
  editDataMapping: [
    { key: 'name', required: true },
    { key: 'code', required: true },
    { key: 'sortColumn', required: true },
    { key: 'sortDirection', required: true },
    { key: 'objectName', required: true },
    { key: 'serviceName', required: true },
    { key: 'isAuth' },
    { key: 'twoColumns' },
    { key: 'objectFields' },
    { key: 'tableDataMapping' },
    { key: 'editDataMapping' },
    { key: 'createDataMapping' },
    { key: 'updatedBy' },
    { key: 'updatedOn' },
  ],
  createDataMapping: [
    { key: 'name', required: true },
    { key: 'code', required: true },
    { key: 'sortColumn', required: true },
    { key: 'sortDirection', required: true },
    { key: 'objectName', required: true },
    { key: 'serviceName', required: true },
    { key: 'isAuth' },
    { key: 'twoColumns' },
    { key: 'objectFields' },
    { key: 'tableDataMapping' },
    { key: 'editDataMapping' },
    { key: 'createDataMapping' }
  ],
  viewDataTransform: (data) => {
    const formattedUpdatedDateTime = renderTimestamp(data.updatedOn);
    const numFields = (data.objectFields && (Object.keys(data.objectFields)).length) || 0;
    return <>
      <h2>{data.name}</h2>
      <p className='uk-text-lead'>Object Configuration</p>
      <p>This object has {numFields} tracked fields.</p>
      <p>{data.tableDataMapping?.length || 0} of these fields are shown in the table.</p>
      <p>{data.editDataMapping?.length || 0} of these fields are shown while editing the object.</p>
      <p>{data.createDataMapping?.length || 0} of these fields are shown while creating a new object.</p>
      <p>By default, requests are sorted by {data.sortColumn}.</p>
      <p>The <code>{data.serviceName}</code> service manages this data.</p>
      {data.updatedBy && <p>Last updated by {data.updatedBy} on {formattedUpdatedDateTime}</p>}
    </>;
  }
};

export const defaultConfig_adminPage = {
  objectName: 'Page',
  objectFields: {
    id: { label: 'Page Id', type: 'text' },
    title: { label: 'Title', type: 'text' },
    label: { label: 'Label', type: 'text' },
    path: { label: 'Path', type: 'text' },
    code: { label: 'Code', type: 'text' },
    content: { label: 'Content', type: 'quill' },
    pageType: { label: 'Page Type', type: 'text' },
    firestoreService: { label: 'Firestore Service Name', type: 'text' },
    rootObject: { label: 'Root Object', type: 'reference', references: 'dynamicObjectService' },
    alwaysShow: { label: 'Always Show in Navbar', type: 'boolean' },
    isAuth: { label: 'Secure Page', type: 'boolean' }
  },
  tableDataMapping: [
    { key: 'id', viewLink: true },
    { key: 'label' },
    { key: 'path' },
    { key: 'pageType' }
  ],
  editDataMapping: [
    { key: 'path', required: true },
    { key: 'code', required: true },
    { key: 'title', required: true },
    { key: 'label', required: true },
    { key: 'pageType', required: true },
    { key: 'firestoreService' },
    { key: 'rootObject' },
    { key: 'alwaysShow' },
    { key: 'isAuth' },
    { key: 'content' }
  ],
  createDataMapping: [
    { key: 'path', required: true },
    { key: 'code', required: true },
    { key: 'title', required: true },
    { key: 'label', required: true },
    { key: 'pageType', required: true },
    { key: 'firestoreService' },
    { key: 'rootObject' },
    { key: 'alwaysShow' },
    { key: 'isAuth' },
    { key: 'content' }
  ],
  viewDataTransform: (data) => {
    const quillDataContent = (data.content && JSON.parse(data.content)) || undefined;
    const htmlTemplate = data.htmlContent || undefined;
    if (quillDataContent) {
      return <QuillField id={`page_content_${data.code}`} readOnly={true} content={quillDataContent} />;
    } else if (htmlTemplate) {
      const htmlOutput = Eta.render(htmlTemplate, data);
      return <div dangerouslySetInnerHTML={{__html: htmlOutput}} />;
    } else {
      return <>
        <h3>{data.name}</h3>
        <p>Dynamically-generated content from database</p>
      </>;
    }
  }
};
