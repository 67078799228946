import firebase from 'firebase/app';
import React, { PureComponent } from 'react';
import { LabeledControl } from '../components/Util';
import { auth_labels } from '../resources/labels';

class LoginPage extends PureComponent {
  email_input_ref = React.createRef();
  password_input_ref = React.createRef();
  rem_device_input_ref = React.createRef();
  submitLoginForm = async (e) => {
    e.preventDefault();
    const { context, location } = this.props;
    const { notification, changeRoute, error } = context;
    const email = this.email_input_ref.current.value;
    const password = this.password_input_ref.current.value;
    const rem_device = this.rem_device_input_ref.current.checked;
    if(email && password) {
      try {
        if(!rem_device) {
          await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
        }
        await firebase.auth().signInWithEmailAndPassword(email, password);
        notification('Login Successful', {status: 'success'});
        if(location.pathname==='/login') {
            changeRoute('/');
        }
      } catch({ code }) {
        if(code==="auth/user-not-found" || code==="auth/wrong-password") {
          notification(auth_labels.login_error_credentials, {status:'danger'});
        } else {
          notification(auth_labels.login_error_unknown, {status:'danger'});
        }
        error(code);
      }
    }
  };
  render() {
    return (
      <div className='uk-container uk-container-small uk-margin-top uk-animation-slide-top-small'
          style={{maxWidth:'600px'}}>
        <h2>Login</h2>
        <form className='uk-form-stacked' onSubmit={this.submitLoginForm}>
          <LabeledControl id='email_input' label='Email' required={true} className='uk-margin-bottom'>
            <input className='uk-input' type='email' ref={this.email_input_ref}/>
          </LabeledControl>
          <LabeledControl id='password_input' label='Password' required={true} className='uk-margin-bottom'>
            <input className='uk-input' type='password' ref={this.password_input_ref}/>
          </LabeledControl>
          <LabeledControl id='rem_device_input' label={auth_labels.rem_device} className='uk-margin-bottom'>
            <input className='uk-checkbox' type='checkbox' ref={this.rem_device_input_ref}/>
          </LabeledControl>
          <input type='submit' value={auth_labels.login_button} className='uk-margin uk-button uk-button-default'/>
        </form>
      </div>
    );
  }
}

export default LoginPage;
