import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { withSecureContext } from '../state/AppState';

class AdminPage extends PureComponent {
  render() {
    const { pageList } = this.props.context;
    return (
      <div className='uk-container uk-container-small uk-animation-slide-top-small'>
        <h2>Site Administration</h2>
        <ul className='uk-nav uk-nav-primary'>
          {pageList
            .filter(p=>p.linkLocation==='admin')
            .map(({path, label},i)=>(
              <li key={`admin_link_${path}`}>
                <Link to={path}>{label}</Link>
              </li>
          ))}
        </ul>
      </div>
    )
  }
}

export default withSecureContext(AdminPage);
